/*!
 * Responsive Tables v5.2.5 (http://gergeo.se/RWD-Table-Patterns)
 * This is an awesome solution for responsive tables with complex data.
 * Authors: Nadan Gergeo <nadan@blimp.se> (www.blimp.se), Lucas Wiener <lucas@blimp.se> & "Maggie Wachs (www.filamentgroup.com)"
 * Licensed under MIT (https://github.com/nadangergeo/RWD-Table-Patterns/blob/master/LICENSE-MIT)
 */
!(function(a) {
  'use strict';
  function b() {
    return (
      'undefined' != typeof window.matchMedia ||
      'undefined' != typeof window.msMatchMedia ||
      'undefined' != typeof window.styleMedia
    );
  }
  function c() {
    return 'ontouchstart' in window;
  }
  function d() {
    return !!(
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    );
  }
  function e() {
    if (d()) {
      var a = parseFloat(
        (
          '' +
          (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(
            navigator.userAgent
          ) || [0, ''])[1]
        )
          .replace('undefined', '3_2')
          .replace('_', '.')
          .replace('_', '')
      );
      return a;
    }
    return 0;
  }
  var f = function(b, c) {
    var d = this;
    if (
      ((this.options = c),
      (this.$tableWrapper = null),
      (this.$tableScrollWrapper = a(b)),
      (this.$table = a(b).find('table')),
      1 !== this.$table.length)
    )
      throw new Error(
        'Exactly one table is expected in a .table-responsive div.'
      );
    this.$tableScrollWrapper.attr('data-pattern', this.options.pattern),
      (this.id =
        this.$table.prop('id') ||
        this.$tableScrollWrapper.prop('id') ||
        'id' +
          Math.random()
            .toString(16)
            .slice(2)),
      (this.$tableClone = null),
      (this.$stickyTableHeader = null),
      (this.$thead = this.$table.find('thead')),
      (this.$hdrCells = this.$thead
        .find('tr')
        .first()
        .find('th')),
      (this.$bodyRows = this.$table.find('tbody, tfoot').find('tr')),
      (this.$btnToolbar = null),
      (this.$dropdownGroup = null),
      (this.$dropdownBtn = null),
      (this.$dropdownContainer = null),
      (this.$displayAllBtn = null),
      (this.$focusGroup = null),
      (this.$focusBtn = null),
      (this.displayAllTrigger = 'display-all-' + this.id + '.responsive-table'),
      (this.idPrefix = this.id + '-col-'),
      (this.headerColIndices = {}),
      (this.headerRowIndices = {}),
      this.wrapTable(),
      this.createButtonToolbar(),
      this.buildHeaderCellIndices(),
      this.setupTableHeader(),
      this.setupBodyRows(),
      this.options.stickyTableHeader && this.createStickyTableHeader(),
      this.$dropdownContainer.is(':empty') && this.$dropdownGroup.hide(),
      a(window)
        .bind('orientationchange resize ' + this.displayAllTrigger, function() {
          d.$dropdownContainer.find('input').trigger('updateCheck'),
            a.proxy(d.updateSpanningCells(), d);
        })
        .trigger('resize');
  };
  (f.DEFAULTS = {
    pattern: 'priority-columns',
    stickyTableHeader: !0,
    fixedNavbar: '.navbar-fixed-top',
    addDisplayAllBtn: !0,
    addFocusBtn: !0,
    focusBtnIcon: 'glyphicon glyphicon-screenshot',
    mainContainer: window,
    i18n: { focus: 'Focus', display: 'Display', displayAll: 'Display all' },
  }),
    (f.prototype.wrapTable = function() {
      this.$tableScrollWrapper.wrap('<div class="table-wrapper"/>'),
        (this.$tableWrapper = this.$tableScrollWrapper.parent());
    }),
    (f.prototype.createButtonToolbar = function() {
      var b = this;
      (this.$btnToolbar = a('<div class="btn-toolbar" />')),
        (this.$dropdownGroup = a(
          '<div class="btn-group dropdown-btn-group pull-right" />'
        )),
        (this.$dropdownBtn = a(
          '<button class="btn btn-default dropdown-toggle" data-toggle="dropdown">' +
            this.options.i18n.display +
            ' <span class="caret"></span></button>'
        )),
        (this.$dropdownContainer = a('<ul class="dropdown-menu"/>')),
        this.options.addFocusBtn &&
          ((this.$focusGroup = a('<div class="btn-group focus-btn-group" />')),
          (this.$focusBtn = a(
            '<button class="btn btn-default">' +
              this.options.i18n.focus +
              '</button>'
          )),
          this.options.focusBtnIcon &&
            this.$focusBtn.prepend(
              '<span class="' + this.options.focusBtnIcon + '"></span> '
            ),
          this.$focusGroup.append(this.$focusBtn),
          this.$btnToolbar.append(this.$focusGroup),
          this.$focusBtn.click(function() {
            a.proxy(b.activateFocus(), b);
          }),
          this.$bodyRows.click(function() {
            a.proxy(b.focusOnRow(a(this)), b);
          })),
        this.options.addDisplayAllBtn &&
          ((this.$displayAllBtn = a(
            '<button class="btn btn-default">' +
              this.options.i18n.displayAll +
              '</button>'
          )),
          this.$dropdownGroup.append(this.$displayAllBtn),
          this.$table.hasClass('display-all') &&
            this.$displayAllBtn.addClass('btn-primary'),
          this.$displayAllBtn.click(function() {
            a.proxy(b.displayAll(null, !0), b);
          })),
        this.$dropdownGroup
          .append(this.$dropdownBtn)
          .append(this.$dropdownContainer),
        this.$btnToolbar.append(this.$dropdownGroup),
        this.$tableScrollWrapper.before(this.$btnToolbar);
    }),
    (f.prototype.clearAllFocus = function() {
      this.$bodyRows.removeClass('unfocused'),
        this.$bodyRows.removeClass('focused');
    }),
    (f.prototype.activateFocus = function() {
      this.clearAllFocus(),
        this.$focusBtn && this.$focusBtn.toggleClass('btn-primary'),
        this.$table.toggleClass('focus-on');
    }),
    (f.prototype.focusOnRow = function(b) {
      if (this.$table.hasClass('focus-on')) {
        var c = a(b).hasClass('focused');
        this.clearAllFocus(),
          c || (this.$bodyRows.addClass('unfocused'), a(b).addClass('focused'));
      }
    }),
    (f.prototype.displayAll = function(b, c) {
      this.$displayAllBtn && this.$displayAllBtn.toggleClass('btn-primary', b),
        this.$table.toggleClass('display-all', b),
        this.$tableClone && this.$tableClone.toggleClass('display-all', b),
        c && a(window).trigger(this.displayAllTrigger);
    }),
    (f.prototype.preserveDisplayAll = function() {
      var b = 'table-cell';
      a('html').hasClass('lt-ie9') && (b = 'inline'),
        a(this.$table)
          .find('th, td')
          .css('display', b),
        this.$tableClone &&
          a(this.$tableClone)
            .find('th, td')
            .css('display', b);
    }),
    (f.prototype.createStickyTableHeader = function() {
      var b = this;
      (b.$tableClone = b.$table.clone()),
        b.$tableClone.prop('id', this.id + '-clone'),
        b.$tableClone.find('[id]').each(function() {
          a(this).prop('id', a(this).prop('id') + '-clone');
        }),
        b.$tableClone.wrap('<div class="sticky-table-header"/>'),
        (b.$stickyTableHeader = b.$tableClone.parent()),
        b.$stickyTableHeader.css('height', b.$thead.height() + 2),
        b.$table.before(b.$stickyTableHeader),
        a(this.options.mainContainer).bind('scroll', function() {
          a.proxy(b.updateStickyTableHeader(), b);
        }),
        a(window).bind('resize', function(c) {
          a.proxy(b.updateStickyTableHeader(), b);
        }),
        a(b.$tableScrollWrapper).bind('scroll', function() {
          a.proxy(b.updateStickyTableHeader(), b);
        }),
        (b.useFixedSolution = !d() || e() >= 8),
        b.useFixedSolution
          ? b.$tableScrollWrapper.addClass('fixed-solution')
          : b.$tableScrollWrapper.addClass('absolute-solution');
    }),
    (f.prototype.updateStickyTableHeader = function() {
      var b = this,
        c = 0,
        d = b.$table.offset().top,
        e = a(this.options.mainContainer).scrollTop() - 1,
        f = b.$table.height() - b.$stickyTableHeader.height(),
        g = e + a(this.options.mainContainer).height() - a(document).height(),
        h = 0;
      if (a(b.options.fixedNavbar).length) {
        var i = a(b.options.fixedNavbar).first();
        (h = i.height()), (e += h);
      }
      var j;
      if (
        ((j =
          this.options.mainContainer === window
            ? e > d && e < d + b.$table.height()
            : d <= 0 && -d < b.$table.height()),
        b.useFixedSolution)
      ) {
        if (
          (b.$stickyTableHeader.scrollLeft(b.$tableScrollWrapper.scrollLeft()),
          (c = h - 1),
          this.options.mainContainer === window && e - d > f
            ? ((c -= e - d - f),
              b.$stickyTableHeader.addClass('border-radius-fix'))
            : this.options.mainContainer !== window && -d > f
              ? ((c -= -d - f),
                b.$stickyTableHeader.addClass('border-radius-fix'))
              : b.$stickyTableHeader.removeClass('border-radius-fix'),
          j)
        )
          return void b.$stickyTableHeader.css({
            visibility: 'visible',
            top: c + 'px',
            width: b.$tableScrollWrapper.innerWidth() + 'px',
          });
        b.$stickyTableHeader.css({ visibility: 'hidden', width: 'auto' });
      } else {
        var k = 400;
        (c = this.options.mainContainer === window ? e - d - 1 : -d - 1),
          c < 0 ? (c = 0) : c > f && (c = f),
          this.options.mainContainer === window && g > 0 && (c -= g),
          j
            ? (b.$stickyTableHeader.css({ visibility: 'visible' }),
              b.$stickyTableHeader.animate({ top: c + 'px' }, k),
              b.$thead.css({ visibility: 'hidden' }))
            : b.$stickyTableHeader.animate({ top: '0' }, k, function() {
                b.$thead.css({ visibility: 'visible' }),
                  b.$stickyTableHeader.css({ visibility: 'hidden' });
              });
      }
    }),
    (f.prototype.setupTableHeader = function() {
      var b = this;
      b.$hdrCells.each(function(c) {
        var d = a(this),
          e = d.prop('id'),
          f = d.text();
        if (
          (e || ((e = b.idPrefix + c), d.prop('id', e)),
          '' === f && (f = d.attr('data-col-name')),
          d.is('[data-priority]') && d.data('priority') !== -1)
        ) {
          var g = a(
              '<li class="checkbox-row"><input type="checkbox" name="toggle-' +
                e +
                '" id="toggle-' +
                e +
                '" value="' +
                e +
                '" /> <label for="toggle-' +
                e +
                '">' +
                f +
                '</label></li>'
            ),
            h = g.find('input');
          b.$dropdownContainer.append(g),
            g.click(function() {
              h.prop('checked', !h.prop('checked')), h.trigger('change');
            }),
            a('html').hasClass('lt-ie9') &&
              h.click(function() {
                a(this).trigger('change');
              }),
            g.find('label').click(function(a) {
              a.stopPropagation();
            }),
            g
              .find('input')
              .click(function(a) {
                a.stopPropagation();
              })
              .change(function() {
                var c = a(this),
                  d = c.val(),
                  e = b.$tableWrapper.find(
                    '#' + d + ', #' + d + '-clone, [data-columns~=' + d + ']'
                  );
                b.$table.hasClass('display-all') &&
                  (a.proxy(b.preserveDisplayAll(), b),
                  b.$table.removeClass('display-all'),
                  b.$tableClone && b.$tableClone.removeClass('display-all'),
                  b.$displayAllBtn.removeClass('btn-primary')),
                  e.each(function() {
                    var b = a(this);
                    if (c.is(':checked')) {
                      if (
                        !b.closest('thead').length &&
                        'none' !== b.css('display')
                      ) {
                        var d = Math.min(
                          parseInt(b.prop('colSpan')) + 1,
                          b.attr('data-org-colspan')
                        );
                        b.prop('colSpan', d);
                      }
                      b.show();
                    } else !b.closest('thead').length && parseInt(b.prop('colSpan')) > 1 ? b.prop('colSpan', parseInt(b.prop('colSpan')) - 1) : b.hide();
                  });
              })
              .bind('updateCheck', function() {
                'none' !== d.css('display')
                  ? a(this).prop('checked', !0)
                  : a(this).prop('checked', !1);
              });
        }
      }),
        a.isEmptyObject(this.headerRowIndices) ||
          b.setupRow(this.$thead.find('tr:eq(1)'), this.headerRowIndices);
    }),
    (f.prototype.setupBodyRows = function() {
      var b = this;
      b.$bodyRows.each(function() {
        b.setupRow(a(this), b.headerColIndices);
      });
    }),
    (f.prototype.setupRow = function(b, c) {
      var d = this;
      if (!b.data('setup')) {
        b.data('setup', !0);
        var e = 0;
        b.find('th, td').each(function() {
          var b = a(this),
            f = '',
            g = b.prop('colSpan');
          b.attr('data-org-colspan', g), g > 1 && b.addClass('spn-cell');
          for (var h = e; h < e + g; h++) {
            f = f + ' ' + d.idPrefix + c[h];
            var i = d.$table.find('#' + d.idPrefix + c[h]),
              j = i.attr('data-priority');
            j && b.attr('data-priority', j);
          }
          (f = f.substring(1)), b.attr('data-columns', f), (e += g);
        });
      }
    }),
    (f.prototype.buildHeaderCellIndices = function() {
      var b = this,
        c = {};
      (this.headerColIndices = {}), (this.headerRowIndices = {});
      var d = 0,
        e = 0;
      if (
        (this.$thead
          .find('tr')
          .first()
          .find('th')
          .each(function(f) {
            for (
              var g = a(this),
                h = g.prop('colSpan'),
                i = g.prop('rowSpan'),
                j = 0;
              j < h;
              j++
            )
              (b.headerColIndices[d + f + j] = f),
                d + f + j >= 0 && (c[d + f + j - e] = e);
            i > 1 && e++, (d += h - 1);
          }),
        this.$thead.find('tr').length > 2)
      )
        throw new Error(
          'This plugin doesnt support more than two rows in thead.'
        );
      if (2 === this.$thead.find('tr').length) {
        var f = a(this.$thead.find('tr')[1]);
        f.find('th').each(function(a) {
          b.headerRowIndices[a] = b.headerColIndices[c[a] + a];
        });
      }
    }),
    (f.prototype.update = function() {
      (this.$bodyRows = this.$table.find('tbody, tfoot').find('tr')),
        this.setupBodyRows(),
        this.$tableClone.find('tbody, tfoot').remove();
      var a = this.$table.find('tbody, tfoot').clone();
      a.appendTo(this.$tableClone),
        this.$dropdownContainer.find('input').trigger('change');
    }),
    (f.prototype.updateSpanningCells = function() {
      var b = this;
      b.$table.find('.spn-cell').each(function() {
        for (
          var b = a(this),
            c = b.attr('data-columns').split(' '),
            d = c.length,
            e = 0,
            f = 0;
          f < d;
          f++
        )
          'none' === a('#' + c[f]).css('display') && e++;
        e !== d ? b.show() : b.hide(), b.prop('colSpan', Math.max(d - e, 1));
      });
    });
  var g = a.fn.responsiveTable;
  (a.fn.responsiveTable = function(b) {
    return this.each(function() {
      var c = a(this),
        d = c.data('responsiveTable'),
        e = a.extend({}, f.DEFAULTS, c.data(), 'object' == typeof b && b);
      '' !== e.pattern &&
        (d || c.data('responsiveTable', (d = new f(this, e))),
        'string' == typeof b && d[b]());
    });
  }),
    (a.fn.responsiveTable.Constructor = f),
    (a.fn.responsiveTable.noConflict = function() {
      return (a.fn.responsiveTable = g), this;
    }),
    a(document).on('ready.responsive-table.data-api', function() {
      a('[data-pattern]').each(function() {
        var b = a(this);
        b.responsiveTable(b.data());
      });
    }),
    a(document).on(
      'click.dropdown.data-api',
      '.dropdown-menu .checkbox-row',
      function(a) {
        a.stopPropagation();
      }
    ),
    a(document).ready(function() {
      a('html')
        .removeClass('no-js')
        .addClass('js'),
        b() ? a('html').addClass('mq') : a('html').addClass('no-mq'),
        c() ? a('html').addClass('touch') : a('html').addClass('no-touch');
    });
})(jQuery);
